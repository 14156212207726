@import "src/styles/theme";
@import "src/styles/global";

.button {
  flex: 1;
  border-bottom: 10px solid $grey;
  text-align: center;
  padding: 1em;
  color: $grey80;

  @include desktop{
    border-bottom: 5px solid $grey;
  }
}

.selected {
  color: $primaryColor;
  border-bottom: 10px solid $primaryColor;

  @include desktop{
    border-bottom: 5px solid $primaryColor;
  }
}
