@import "src/styles/theme";
@import "src/styles/global";

.header {
  background-color: $primaryColor;
  display: flex;
  justify-content: space-between;
  padding: 0.5em;
  align-items: center;
  @include desktop {
    padding: 1em;
  }
}

.logoContainer {
  max-width: 100%;
  padding: 0 1em;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100px;

    @include desktop {
      width: 100px;
    }
  }
}

.menuContainer {
  display: flex;
  align-items: center;
  justify-content: right;
}


.menuButton {
  border: 1px solid #fff;
  border-radius: 5px;
  color: white;
  padding: 0.3em 0.5em;
  user-select: none;
  cursor: pointer;
  margin-right: 0.5em;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 25px;
  min-width: 25px;
}

.icon {
  color: white;
  font-size: 18px;
}

.padding-l-1 {
  padding-left: 1em;
}


.hideOnMobile {
  display: block;
  width: 50%;
  @include mobile {
    display: none;
  }
}

.isSelected {
  color: $primaryColor;
  background-color: white;

  & .icon {
    color: $primaryColor;
  }
}
