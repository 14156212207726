@import "src/styles/theme";
@import "src/styles/global";

.sectionContainer {
  border-top: 0.5em solid $grey50;
  border-bottom: 0.5em solid $grey50;
  padding: 1em;

  @include desktop {
    display: flex;
    justify-content: center;
    border-top: 0;
    border-bottom: 2px solid $grey50;
    margin: auto;
    max-width: $maxWidth;
    flex-direction: column;
  }
}

.content {
  width: 100%;
  @include desktop {
    max-width: $maxWidth;
    padding-bottom: 2em;
  }
}

.grey{
  background-color: $grey50;
}
