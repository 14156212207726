@import "src/styles/theme";
@import "src/styles/global";

.controls {
  display: none;
  @include desktop {
    display: flex;
    align-items: center;
    min-width: 500px;
  }

  @include tablet {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 500px;
  }
}
