@import "src/styles/theme";
@import "src/styles/global";

.cardContainer {
  border: 1px solid $grey;
  padding: 1em;
  border-radius: 10px;
  background-color: white;
  max-width: 467px;
  margin: 1em 0;

  @include desktop {
    margin: 1em;
    flex-basis: 46%;
    box-sizing: border-box;
  }
}

.cardName {
  font-size: 1em;
  font-weight: bold;
}

.cardSlogan {
  font-size: 0.9em;
}

.cardDescription {
  margin: 0.5em 0 1em 0;
  word-break: break-all
}

.logoContainer {
  & img {
    max-width: 5em;
    padding-right: 1em;
    object-fit: contain;
  }
}

.titleCardContainer {
  display: flex;
  align-items: center;
}

.icon {
  font-size: 20px;
  color: $primaryColor;
  padding-right: 0.5em;
}


.truncate {
  display: -webkit-box;
  -webkit-line-clamp: $maxLinesPerCard;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.center{
  display: flex;
  align-items: center;
}
