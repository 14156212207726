@import "src/styles/theme";
@import "src/styles/global";

.buttonExcel {
  display: flex;
  align-items: center;
  justify-content: right;
}

.searchContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.titleContainer{
  display: flex;
  align-items: center;
}
.title {
  font-size: 1.2em;
  font-weight: lighter;
  padding: 1em 0;
  margin: 0.5em ;

  @include desktop(){
    font-weight: 700;
  }
}

.related {
  background-color: $grey50;
}

.resultContainer {
  width: 100%;
}

.resultSection {
  width: 100%;
  padding-bottom: 0.5em;
  @include desktop {
    max-width: $maxWidth;
    margin: auto;
    padding-bottom: 1.5em;
  }
}

.hide {
  display: none;
}

.flexGrid {
  padding: 0 1em;

  @include desktop {
    padding: 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
  }
}

.hideOnDesktop {
  display: block;
  width: 100%;
  @include desktop {
    display: none
  }

  @include tablet{
    display: none;
  }
}


.showMoreContainer{
  display: flex;
  justify-content: center;
  align-items: center;
}