@import "src/styles/theme";
@import "src/styles/global";

.productContainer {
  @include mobile {
    padding-bottom: 60px;
  }
}

.title {
  font-weight: bold;
  font-size: 1.2em;;
}

.subTitle {
  font-weight: lighter;
  padding: 0.5em 0;

  @include desktop(){
    padding: 1em 0;
  }
}

.section {
  padding: 0.5em 1em;
  border-bottom: 0.5em solid $grey50;
  border-top: 0.5em solid $grey50;
}

.productDescription {
  width: 100%;
  padding: 1em 0;
}

.directionContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 1em 0;
}


.gridImage {
  width: 100%;

  & img {
    width: 150px;
    height: 150px;
  }
}

.related {
  padding: 1em;
  background-color: $grey50;
  margin-bottom: 1em;
}

.container {
  display: grid;
  grid-gap: 40px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: 300px;

  & div {
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @include mobile {
    grid-gap: 1em;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-auto-rows: 150px;
  }
}


