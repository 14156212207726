@import "theme";

@mixin mobile {
  @media only screen and (max-width: $small) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (min-width: $medium) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: $large) {
    @content;
  }
}

@mixin large {
  @media only screen and (min-width: $extraLarge) {
    @content;
  }
}

h1 {
  font-size: 24px;
  @include tablet {
    font-size: 36px;
  }
  @include desktop {
    font-size: 42px;
  }
}

.debug {
  border: 1px solid red;
}
