@import "src/styles/theme";
@import "src/styles/global";

.cardContainer {
  border: 1px solid $grey;
  margin: 1em 0;
  padding: 1em;
  border-radius: 10px;
  max-width: 467px;
  cursor: pointer;
  &:hover {
    background-color: $grey50;
  }

  @include desktop {
    flex-basis: 46%;
    box-sizing: border-box;
    margin: 1em;
  }

}

.cardTitle {
  font-size: 1em;
  font-weight: bold;
}

.cardSubTitle {
  font-size: 0.9em;
  margin: 0 0 1em 0;
}

.cardDescription {
  margin: 0.5em 0 1em 0;
}

.truncate {
   display: -webkit-box;
   -webkit-line-clamp: $maxLinesPerCard;
   -webkit-box-orient: vertical;
   overflow: hidden;
}


.logoContainer {
  & img {
    max-width: 5em;
    padding-right: 1em;
    object-fit: contain;
  }
}

.titleCardContainer {
  display: flex;
  align-items: center;
}
