@import "src/styles/theme";
@import "src/styles/global";

.companyContainer {
  @include mobile {
    padding-bottom: 65px;
  }
}

.logoContainer {
  padding: 0 1em 0 0;
  max-width: 120px;

  & img {
    width: 100%;
    height: 70px;
    border-radius: 5px;
    object-fit: contain;
  }
}

.title {
  width: 100%;
  font-weight: bold;
  font-size: 1.2em;
}

.directionContainer {
  padding: 0.5em 0;
}

.dataTitle {
  color: $primaryColor;
  margin-bottom: 1em;
  font-size: 1em;
}

.moreOffices {
  display: flex;
  justify-content: right;
  align-items: center;
}

.subtitle {
  width: 100%;
  font-weight: lighter;
  color: $grey80;
}

.streetContainer {
  display: flex;
  align-items: center;
  padding-bottom: 0.5em;
}

.padding {
  padding: 1em;
}

.icon {
  color: $primaryColor;
  font-size: 24px;
  padding-right: 0.2em;
}

.contactContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  @include desktop {
    max-width: 300px;
  }
}

.contactRow {
  display: flex;
  margin: 0.3em 0;
}

.descriptionContainer {

  @include desktop {
    width: 100%
  }
}


.dataContainer {

  @include desktop {
    display: flex;
  }
}


.description {
  overflow-wrap: break-word;

  @include mobile {
    margin: 1em 0;
  }
  @include desktop {
    padding: 1em 2em 1em 0;
  }
}
