@import "src/styles/theme";
@import "src/styles/global";

.alertContainer{
  background-color: $grey50;
  padding: 1em 2em;
  margin: 2em 0 -1em 0;
  border-radius: 10px;
  border: 2px solid $primaryColor;

  @include desktop{
    margin: 2em 0 -3em 0;
  }
}
