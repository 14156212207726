//Breakpoints
$small: 576px;
$medium: 768px;
$large: 992px;
$extraLarge: 1200px;

$maxWidth: 1024px;

$maxLinesPerCard: 4;
//colors
$primaryColor: #3c8a94;
$secondaryColor: #f4f4f5;
$grey: #d9d9d9;
$grey50: #f4f4f5;
$grey60: #c6c6c6;
$grey80: #8b8c91;

