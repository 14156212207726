@import "src/styles/theme";
@import "src/styles/global";

.categoriesContainer {
  width: 100%;
}

.titleSection {
  @include desktop {
    text-align: left;
    flex: 1;
    padding: 1em;

    & > h2 {
      margin: 0 0 1em 0;
    }
  }
}

.categoriesChipSection {
  @include desktop {
    padding: 1em;
    flex: 2;
  }
}

.categoriesSection {
  @include mobile {
    padding: 1em 1em 5em 1em;
  }

  @include desktop {
    display: flex;
    padding-top: 5em;
    padding-right: 10em;
    padding-left: 10em;
  }
}

.stickyButton {
  background-color: $grey80;
  color: $grey;
  border-radius: 10px;
  font-size: 1.2em;
  text-decoration: none;
  user-select: none;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  margin: auto 1em;
  @include desktop {
    cursor: pointer;
    padding: 0.5em 1em;
    max-width: 400px;
    margin: 1em 0 0 0;
    max-height: 70px;
  }

  @include mobile{
    min-height: 40px;
  }
}

.enabled {
  background-color: $primaryColor;
  color: white;
}

.hiddenMobile {
  @include mobile {
    display: none;
  }
}

.hiddenDesktop {
  @include desktop {
    display: none;
  }
}

.hiddenTablet {
  @include tablet {
    display: none;
  }
}
