@import "src/styles/theme";
@import "src/styles/global";
.button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5em 0.5em;
    //margin-left: 1em;
    margin: 0 0.5em;
    border-radius: 10px;
    user-select: none;
    cursor: pointer;
    background-color: $grey50;
}

.fullWidth {
    width: 100%;
}

.secondaryButton {
    color: $primaryColor;
    &:hover {
        background-color: $grey60;
    }
}

.icon {
    font-size: 22px;
}

.primaryButton {
    color: white;
    background-color: $primaryColor;
}

.noMargin {
    margin: 0;
}

.tooltip {
    position: relative;
    // display: inline-block;
    // border-bottom: 1px dotted black;
    /* If you want dots under the hoverable text */
}


/* Tooltip text */

.tooltip .tooltiptext {
    visibility: hidden;
    background-color: $primaryColor;
    color: white;
    text-align: center;
    padding: 5px 5px;
    border-radius: 6px;
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
    top: 110%;
}


/* Show the tooltip text when you mouse over the tooltip container */

.tooltip:hover .tooltiptext {
    visibility: visible;
}