@import "src/styles/theme";
@import "src/styles/global";

.chip {
  border: 1px solid $grey80;
  font-size: 0.9em;
  border-radius: 20px;
  height: 30px;
  padding: 0 1rem 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
  user-select: none;
  cursor: pointer;

  @include desktop {
    margin: 0.5em;
  }
}

.selected {
  background-color: $primaryColor;
  color: white;
  border: 1px solid $primaryColor;
}

.icon {
  font-size: 18px;
  padding-left: 0.3em;
}
