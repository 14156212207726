@import "src/styles/theme";
@import "src/styles/global";


.titleContainer{
  font-size: 1.1em;
}

h4{
  font-weight: normal;
  color: $primaryColor;
  @include desktop{
    font-weight: 700;
  }
}
