@import "src/styles/theme";
@import "src/styles/global";

.stickyButtonContainer {
  display: none;
  @include mobile {
    display: flex;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 0.5em 0;
    box-shadow: 0px -5px 10px $grey;
    background-color: white;
    height: 50px;
  }

}

.hide {
  display: none;
}
