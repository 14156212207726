@import "src/styles/theme";
@import "src/styles/global";

.listContainer {
  @include desktop {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    width: 100%;
  }
}

.containerInput{
  justify-content: center;
  height: 4em;
  display: flex;
  align-items: center;
}