@import "src/styles/theme";
@import "src/styles/global";

.favoritesContainer {
  @include mobile {
    padding-bottom: 60px;
  }
  @include desktop {
    max-width: $maxWidth;
    margin: auto;
    padding-top: 2em;
  }
}

.emptySection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: $grey60;
  padding: 5em 2em;

  & p {
    text-align: center;
    padding: 0 2em;
  }
}

.icon {
  font-size: 10em;
}

.flexGrid {
  padding: 0 1em;
  @include desktop {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
  }
}

.buttonContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.buttonDesktop {
  display: none;
  @include desktop {
    display: flex;
    max-width: 400px;
  }
}
