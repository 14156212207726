@import "src/styles/theme";
@import "src/styles/global";

.collapsible .content {
  padding: 6px;
  background-color: #eeeeee;
  @include desktop {
    margin: 1em;
    flex-basis: 46%;
    box-sizing: border-box;
  }
}
.collapsible .header {
  background-color: #d61919;
  padding: 1em;
  cursor: pointer;
  max-width: 467px;
  @include desktop {
    margin: 1em;
    flex-basis: 46%;
    box-sizing: border-box;
  }
}

.cardContainer {
  border: 1px solid $grey;
  padding: 1em;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.931);
  max-width: 467px;
  margin: 1em 0;
  

  @include desktop {
    margin: 1em;
    flex-basis: 46%;
    box-sizing: border-box;
  }
}

.cardName {
  font-size: 1em;
  font-weight: bold;
}

.cardSlogan {
  font-size: 0.9em;
}

.cardDescription {
  margin: 0.5em 0 1em 0;
  word-break: break-word
}

.logoContainer {
  & img {
    max-width: 5em;
    padding-right: 1em;
    object-fit: contain;
  }
}

.titleCardContainer {
  display: flex;
  align-items: center;
}

.icon {
  font-size: 20px;
  color: $primaryColor;
  padding-right: 0.5em;
}


.truncate {
  display: -webkit-box;
  -webkit-line-clamp: $maxLinesPerCard;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.center{
  display: flex;
  align-items: center;
}

.descriptionContainer {

  @include desktop {
    width: 100%
  }
}


.dataContainer {

  @include desktop {
    display: flex;
  }
}

.contactContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  @include desktop {
    max-width: 300px;
  }
}

.contactRow {
  display: flex;
  margin: 0.3em 0;
}

//button

.stickyButton {
  border: 1px solid $grey;
  background-color: rgba(255, 255, 255, 0.63);
  color: $primaryColor;
  border-radius: 10px;
  font-size: 1.2em;
  text-decoration: none;
  user-select: none;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 467px;

  margin: 1em 0;
  @include desktop {
    width: 50%;
    margin: 1em 2em;
    padding: 0.5em;
    cursor: pointer;
    min-width: 400px;
    max-width: 400px;
    max-height: 70px;
  }

  @include mobile{
    min-height: 40px;
  }
}

.directionContainer {
  padding: 0.5em 0;
}

.dataTitle {
  color: $primaryColor;
  margin-bottom: 1em;
  font-size: 1em;
}

.streetContainer {
  display: flex;
  align-items: center;
  padding-bottom: 0.5em;
}