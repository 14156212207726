@import "src/styles/theme";
@import "src/styles/global";

.partnerContainer {
  border: 1px solid $grey;
  border-radius: 10px;
  padding: 1em;
  margin: 0 0 1em 0;

  @include desktop {
    flex-basis: 46%;
    box-sizing: border-box;
    margin: 1em;
  }

  @include tablet {
    flex-basis: 46%;
    box-sizing: border-box;
    margin: 1em;
  }
}

.partnerTitle {

}


.partnerDescription {

}

.title {
  font-weight: bold;
  font-size: 1.1em;
}
