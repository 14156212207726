@import "src/styles/theme";
@import "src/styles/global";

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include desktop {
    justify-content: left;
  }
  @include tablet {
    justify-content: left;
  }
}

.imageContainer {
  margin: 0.5rem;

  @include desktop {
    width: calc(33.3333% - 1rem);
  }

  @include tablet {
    width: calc(33.3333% - 1rem);
  }

}

.image {
  width: 100%;
  object-fit: contain;
  box-shadow: 0 10px 14px rgba(0,0,0,0.2);
  @include desktop{
    height: 250px;
  }
}
