@import "src/styles/theme";
@import "src/styles/global";
.searchBarContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  input {
    border-top: 2px solid $grey;
    border-bottom: 2px solid $grey;
    border-left: 2px solid white;
    border-right: 2px solid white;
    padding: 0.5em;
    text-align: left;
    width: 100%;
    height: 50px;
  }

  @include desktop{
    max-width: $maxWidth;
  }
}

.buttonSearch {
  background-color: $grey;
  display: flex;
  align-items: center;
  padding: 0 1em ;
  text-decoration: none;
  user-select: none;
  cursor: pointer;
  height: 50px;
}

.available {
  background-color: $primaryColor;
  color: white;
}
