@import "src/styles/theme";
@import "src/styles/global";

.popup {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.65);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5em 1em;
  z-index: 999999;
  box-sizing: border-box;
}

.popupInner {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.popupBody{
  z-index: 2;
  position: relative;
  margin: 0 auto;
  background-color: white;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 10px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100%;
  padding: 1em;
}

.buttonContainers{
  display: flex;
  justify-content: space-around;
}
.closeContainer{
  display: flex;
  justify-content: flex-end;
}

.textContainer{
  display: flex;
  align-items: center;
  padding: 1em;
  font-size: 24px;

  @include mobile{
    font-size: 18px;
  }
}

.icon{
  font-size: 26px;
  color: $grey80;
}

.bodyIcon{
  font-size: 26px;
  color: $primaryColor;
}
