@import "src/styles/theme";
@import "src/styles/global";

.icon {
  color: $primaryColor;
  padding-right: 0.2em;
  font-size: 20px;
}

.directionContainer {
  display: flex;
  flex-direction: row;
  margin: 1em 0;
  overflow: hidden;
  @include desktop {
    max-width: 500px;
    width: 100%;
  }

}

.block {
  display: flex;

  &:nth-child(2) {
    border-left: 2px solid $grey60;
    padding-left: 0.5em;
    margin-left: 1em;
  }

  @include desktop {
    justify-content: left;
    width: 100%;
  }
}
