@import "src/styles/theme";
@import "src/styles/global";

.titleContainer{
  border-top: 0.5em solid $grey50;
  border-bottom: 0.5em solid $grey50;
  background-color: $grey50
}

.titleContent{
  background-color: $grey50;
  padding: 1em;

  @include desktop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: $maxWidth;
    margin: auto;
  }
}
