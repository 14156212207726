@import "src/styles/theme";
@import "src/styles/global";

.categoriesFlatSection {
  border-bottom: 0.5em solid $grey;
  display: flex;
  flex-direction: column;
  padding: 1em;
}

.title {
  font-size: 0.9em;
  color: $grey80;
  margin-bottom: 0.5em;
  font-weight: bold;
}

.filterContent{
  @include desktop{
    max-width: $maxWidth;
    margin: auto;
    width: 100%;
  }
}
