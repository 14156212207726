@import "src/styles/theme";
@import "src/styles/global";


.favoriteLabel {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 15px;
  font-weight: 400;
  color: $grey80;
  margin: 0.5em 0;
  user-select: none;
  cursor: pointer;

  & .iconFav {
    padding-left: 0.5em;
    font-size: 24px;
  }
}


.favoriteLabelAdded{
  color: $primaryColor;
}
