@import "src/styles/theme";
@import "src/styles/global";

.cardEmployeeContainer {
  display: flex;
  flex-direction: column;

  @include desktop {
    flex-direction: row;
    flex-wrap: wrap;
  }

}

.cardEmployee {
  display: flex;
  justify-content: center;
  align-items: center;
  @include desktop {
    width: 100%;
    flex: 0 50%;
    padding: 1em 2em;
    max-width: 50%;
    box-sizing: border-box;
  }
}

.imgContainer {

  & img {
    border-radius: 50%;
    width: 90px;
    height: 90px;
    overflow: hidden;
    object-fit: cover;
    box-shadow: 0 10px 14px rgba(0,0,0,0.2);

    @include mobile {
      width: 70px;
      height: 70px;
    }
  }


}

.dataContainer {
  padding: 1em 0.5em;
  color: $grey80;
  width: 100%;

}

.name {
  color: black;
}

.contactContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $grey50;
  padding: 0.5em;
  margin: 0.5em;
  border-radius: 10px;
}

.buttonContainerDesktop {
  @include mobile {
    display: none;
  }
}

.buttonContainerMobile {
  display: none;
  @include mobile() {
    display: flex;
    padding: 0.5em 0;
  }
}
