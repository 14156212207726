@import "src/styles/theme";
@import "src/styles/global";

.videoContainer {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
  @include desktop{
    width: $maxWidth;
  }
}

.videoFrame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
